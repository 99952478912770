.multiSelect {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.selectContainer, .operatorsContainer, .regroupingContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin: 5px;
}

.select {
    display: flex;
    max-height: 25px;
    margin: 5px;
}

.valueRangeContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin: 0px 10px 0px 10px;
    width: 800px;  
}

@media (max-width: 820px) {
    .valueRangeContainer, .pageSettings {
        flex-direction: column !important;
        align-items: center !important;
        width: auto !important
    }
    .valueRange {
        margin-bottom: 10px !important;
    }
    .pageSettings {
        width: auto !important;
    }
}

@media (max-width: 500px) {
    #pdfViewer {
        display: none !important;
    }
    .operatorsContainer, .regroupingContainer {
        height: 140px;
        align-items: flex-start !important;
                
        .p-button {
            border-radius: 6px !important;
            border-right-style: solid !important;
            border-right-width: 1px !important;
            border-color: rgb(209, 213, 219) !important;
        }
    }
    .regroupingContainer {
        height: 100px;
    }
    .operators, .regrouping {
        flex-direction: column !important;
    }

    h1 {
        margin-top: 50px !important;
    }

    #pdfViewer {
        visibility: hidden;
    }

    .pageSettingsCol2 {
        width: 350px;;
    }
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 10px;
  }

.valueRange {
    width: 400px;
    margin: 0px 10px 0px 10px;
}

.checkbox {
    margin: 5px;
}

.checkbox label {
    margin: 3px;
    font-size: 15px;
    font-weight: 700;
}

.settingsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 10px 0px 10px;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.pageSettings {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0px 10px 0px 10px;
    width: 800px;
    justify-content: space-around;
}

.pageSettingsCol1 {
    margin: 10px;
    vertical-align: top;
    width: 260px;
}

.pageSettingsCol2 {
    margin: 10px;
    vertical-align: top;
    width: 250px;
}

.pageSettingsCol2 div {
    text-align: center;
}

.hidden {
    visibility: hidden;
}

.clipboardMessage {
    color: green;
}

.pagesInput {
    width: 50px;
    height: 25px;
}

.pagesInput input {
    width: 50px;
}

.pagesInput button {
    margin: 0px;
}

button {
    height: 20px;
    margin: 2px;
}

.radioButtons span {
    margin: 5px;
    font-size: 15px;
    align-content: center;
    font-weight: 700;
}

.radioButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.answerPagesButton, .remainderToggle {
    width: 70px;
    height: 25px;
    margin: 5px 0px 5px 5px;
}

.answerPagesContainer, .remainderPagesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

header {
    font-size: 20px;
}

.openPdf {
    margin-top: 10px;
}

.generateUrl {
    margin-top: 5px;
}